var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "bg-primary",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _vm.isMobile
        ? _c(
            "b-navbar-brand",
            {
              staticClass: "logo",
              attrs: {
                "b-link": "",
                to: "/",
                "aria-label": "Česko-slovenské slovo týdne / týždňa",
              },
            },
            [_c("span", { staticClass: "logo-img" })]
          )
        : _c(
            "b-navbar-brand",
            {
              staticClass: "logo",
              attrs: {
                "b-link": "",
                to: "/",
                "aria-label": "Česko-slovenské slovo týdne / týždňa",
              },
            },
            [_c("span", { staticClass: "logo-img" })]
          ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Zobraz menu",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "normal" } },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Domů / Domov")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/archive", exact: "" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "normal" } },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "archive" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Archiv")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/media", exact: "" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "normal" } },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "newspaper" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("V médiích / médiách")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/about", exact: "" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "normal" } },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "info-circle" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Info")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              staticStyle: { "font-weight": "normal" },
                            },
                            [_vm._v("Entities")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("entities-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/admin"),
                      },
                      attrs: {
                        right: "",
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              staticStyle: { "font-weight": "normal" },
                            },
                            [_vm._v("Administration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/user-management",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "users" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("User management")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/metrics",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Metrics")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/health",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Health")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/configuration",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Configuration")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/logs",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Logs")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.openAPIEnabled
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/docs",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "book" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("API")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "logout",
                                id: "logout",
                                "active-class": "active",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.logout()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "sign-out-alt" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Sign out")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }