var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer",
      staticStyle: {
        "background-color": "#313a45",
        color: "#ffffff",
        "padding-top": "2em",
        "padding-bottom": "1em",
      },
      attrs: { id: "footer" },
    },
    [
      _c("div", { staticClass: "home row" }, [
        _c("div", { staticClass: "col-md-4" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "home row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("p", { staticClass: "centered p-dense" }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "envelope", color: "#ffffff" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#ffffff",
                          "font-size": "smaller",
                          "font-weight": "normal",
                        },
                        attrs: {
                          href: "mailto:michal.skrabal@ff.cuni.cz?subject=%C4%8Cesko-slovensk%C3%A9%20slovo%20t%C3%BDdne%20%2F%20t%C3%BD%C5%BEd%C5%88a",
                        },
                      },
                      [_vm._v("Michal Škrabal")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("p", { staticClass: "centered p-dense" }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "envelope", color: "#ffffff" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#ffffff",
                          "font-size": "smaller",
                          "font-weight": "normal",
                        },
                        attrs: {
                          href: "mailto:vladimir.benko@juls.savba.sk?subject=%C4%8Cesko-slovensk%C3%A9%20slovo%20t%C3%BDdne%20%2F%20t%C3%BD%C5%BEd%C5%88a",
                        },
                      },
                      [_vm._v("Vladimír Benko")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "footer-text centered" }, [
        _c("br"),
        _c("br"),
        _vm._v("\n    © 2022–2023\n    "),
        _c(
          "a",
          {
            staticClass: "footer-a",
            staticStyle: { color: "#ffffff", "font-weight": "normal" },
            attrs: { href: "https://www.juls.savba.sk", target: "_blank" },
          },
          [_vm._v("Jazykovedný ústav Ľudovíta Štúra SAV,\n    ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "footer-a",
            staticStyle: { color: "#ffffff", "font-weight": "normal" },
            attrs: { href: "https://ucnk.ff.cuni.cz/cs/", target: "_blank" },
          },
          [_vm._v("Ústav Českého národního korpusu.\n    ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "navbar-version" }, [
          _vm._v(_vm._s(_vm.version)),
        ]),
        _vm._v(" "),
        _c("br"),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "home row", staticStyle: { "margin-bottom": "0.9em" } },
      [
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c("b", [_vm._v("Slovo týdne")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c("b", [_vm._v("Slovo týždňa")]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/about", target: "_blank" },
              },
              [_vm._v("O projektu")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/about", target: "_blank" },
              },
              [_vm._v("O projekte")]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/archive", target: "_blank" },
              },
              [_vm._v("Archiv příspěvků")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/archive", target: "_blank" },
              },
              [_vm._v("Archív príspevkov")]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/media", target: "_blank" },
              },
              [_vm._v("V mediích")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "p",
          {
            staticClass: "centered",
            staticStyle: { "margin-bottom": "0.7em" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  color: "#ffffff",
                  "font-size": "smaller",
                  "font-weight": "normal",
                },
                attrs: { href: "/media", target: "_blank" },
              },
              [_vm._v("V médiách")]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "home row",
        staticStyle: { "margin-top": "1.5em", "margin-bottom": "0.9em" },
      },
      [
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c("b", [_vm._v("Napište nám")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c("b", [_vm._v("Napíšte nám")]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "home row",
        staticStyle: { "margin-top": "1.5em", "margin-bottom": "0.9em" },
      },
      [
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/CzechNatCorp",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/images/facebook3.svg",
                    width: "40px",
                    height: "40px",
                    alt: "Facebook UCNK",
                  },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("p", { staticClass: "centered p-dense" }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/JazykovednyUstavLudovitaStura",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/images/facebook3.svg",
                    width: "40px",
                    height: "40px",
                    alt: "Facebook JULS",
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }