import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const WordPair = () => import('@/entities/word-pair/word-pair.vue');
// prettier-ignore
const WordPairUpdate = () => import('@/entities/word-pair/word-pair-update.vue');
// prettier-ignore
const WordPairDetails = () => import('@/entities/word-pair/word-pair-details.vue');
// prettier-ignore
const Home = () => import('@/core/home/home.vue');
// prettier-ignore
const Author = () => import('@/entities/author/author.vue');
// prettier-ignore
const AuthorUpdate = () => import('@/entities/author/author-update.vue');
// prettier-ignore
const AuthorDetails = () => import('@/entities/author/author-details.vue');
// prettier-ignore
const GeneralParam = () => import('@/entities/general-param/general-param.vue');
// prettier-ignore
const GeneralParamUpdate = () => import('@/entities/general-param/general-param-update.vue');
// prettier-ignore
const GeneralParamDetails = () => import('@/entities/general-param/general-param-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'word-pair',
      name: 'WordPair',
      component: WordPair,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'word-pair/new',
      name: 'WordPairCreate',
      component: WordPairUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'word-pair/:wordPairId/edit',
      name: 'WordPairEdit',
      component: WordPairUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'word-pair/:wordPairId/view',
      name: 'WordPairView',
      component: WordPairDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'author',
      name: 'Author',
      component: Author,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'author/new',
      name: 'AuthorCreate',
      component: AuthorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'author/:authorId/edit',
      name: 'AuthorEdit',
      component: AuthorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'author/:authorId/view',
      name: 'AuthorView',
      component: AuthorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'general-param',
      name: 'GeneralParam',
      component: GeneralParam,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'general-param/new',
      name: 'GeneralParamCreate',
      component: GeneralParamUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'general-param/:generalParamId/edit',
      name: 'GeneralParamEdit',
      component: GeneralParamUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'general-param/:generalParamId/view',
      name: 'GeneralParamView',
      component: GeneralParamDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/:wordPairId',
      name: 'HomeView',
      component: Home,
    },
    {
      path: '/next/:wordPairId',
      name: 'HomeNextView',
      component: Home,
      meta: { lookupNext: true },
    },
    {
      path: '/prev/:wordPairId',
      name: 'HomePrevView',
      component: Home,
      meta: { lookupPrev: true },
    },

    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
